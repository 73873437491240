import React, { useState } from "react";
import useBreakPoints from "../Hooks/useBreakPoints";

const floorPlan = [
    {
        imagePath: "/images/first_floor.jpeg",
        title: "FIRST FLOOR PLAN"
    },
    {
        imagePath: "/images/second_floor.jpeg",
        title: "SECOND FLOOR PLAN"
    },
    {
        imagePath: "/images/third_floor.jpeg",
        title: "THIRD FLOOR PLAN"
    },
    {
        imagePath: "/images/forth_floor.jpg",
        title: "FOURTH FLOOR PLAN"
    },
    {
        imagePath: "/images/fifh_floor.jpeg",
        title: "FIFTH FLOOR PLAN"
    },
    {
        imagePath: "/images/sixth_floor.jpeg",
        title: "SIXTH FLOOR PLAN"
    },
    {
        imagePath: "/images/seventh_floor.jpeg",
        title: "SEVENTH FLOOR PLAN"
    },
    {
        imagePath: "/images/eigth_floor.jpeg",
        title: "EIGHTH FLOOR PLAN"
    },
    {
        imagePath: "/images/ninth_floor.jpeg",
        title: "NINETH FLOOR PLAN"
    },
    {
        imagePath: "/images/tenth_floor.jpeg",
        title: "TENTH FLOOR PLAN"
    },
    {
        imagePath: "/images/elventh_floor.jpeg",
        title: "ELEVENTH FLOOR PLAN"
    },
    {
        imagePath: "/images/twelth_floor.jpeg",
        title: "TWELTH FLOOR PLAN"
    }
]


export const FloorPlansGroup = () => {
    const { viewportWidth } = useBreakPoints();
    const [currentImg, setCurrentImg] = useState("/images/first_floor.jpeg");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (imagePath) => {
        setCurrentImg(imagePath);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            closeModal();
        }
    };


    return (
        <>
            <div
                id="floor-plans"
                className={`about-details-page w-[100%] h-[100%] pb-[8vh] 
         ${viewportWidth > 1850 ? 'pl-[15vw] pr-[15vw]' :
                        viewportWidth > 1750 ? 'pl-[14vw] pr-[17vw]' :
                            viewportWidth > 1650 ? 'pl-[12vw] pr-[14vw]' :
                                viewportWidth > 1599 ? 'pl-[12vw] pr-[14vw]' :
                                    'px-[4vw] xs:pt-[2vh] xs:px-[12vw]'}
       `}
                style={{
                    backgroundColor: `${isModalOpen ? '#777777b3' : '#f5f5f5'}`,
                }}>

                <div className="flex w-[100%] h-[100%] flex-wrap gap-x-[1px] gap-y-[4vw] ">
                    {
                        floorPlan.map((floor, index) => (
                            <>
                                <div key={index} className="w-[24.5%] xs:w-[100%] h-[100%] flex flex-col justify-center items-center ">
                                    <div className="w-[100%] h-[200px]">
                                        <img
                                            alt="dummy"
                                            className="w-[100%] h-[100%] object-cover bg-no-repeat"
                                            src={floor.imagePath}
                                        />
                                    </div>
                                    <div className="w-[100%] h-max bg-white text-center flex gap-[10px] py-[10px] flex-col justify-center items-center" >
                                        <div className="text-[#0F0F0F] text-[18px] font-[400]">
                                            {floor.title}
                                        </div>
                                        <div
                                            onClick={() => openModal(floor.imagePath)}
                                            className="font-[400] uppercase text-[16px] px-[12px] flex justify-center items-center border-[1px] border-solid border-[#23344d] hover:bg-[#1b2c49] bg-[#23344d] text-white rounded-[5px] py-[4px] tracking-[1px] cursor-pointer transition-all ease-linear w-max"
                                        >
                                            view floor plan
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                    }

                </div>

                {isModalOpen && (
                    <div
                        onClick={handleOutsideClick}
                        className="modal-overlay fixed top-0 left-0 w-[100vw]  h-[100vh] bg-[#777777b3] z-[9999999] flex justify-center items-center  transition-all duration-300 ease-in-out">
                        <div className="relative transition-all duration-300 ease-in-out xs:border-[1px] xs:border-solid xs:border-white">
                            <img className="transition-all duration-300 ease-in-out max-w-[90vw] xs:max-w-[90vw] max-h-[90vh] object-contain" src={currentImg} alt="Selected" />
                            <button
                                onClick={closeModal}
                                className="absolute top-[-3%] right-[-3%] xs:top-[-7%] xs:right-[-7%] px-[10px] py-[5px] cursor-pointer">
                                <img
                                    className="w-[30px] h-[30px]"
                                    src="/images/close.png"
                                    alt="cross"
                                />

                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
