import React from 'react';

const ParallaxBackground = () => {
  return (
    <>
      <div
        className="fixed inset-0 z-0 bg-cover bg-center bg-fixed bg-no-repeat block w-[100vw] h-[100vh]"
        style={{
          backgroundImage: "url('/images/parallax_banner.jpg')",
          backgroundColor: 'rgba(0, 0, 0, 0.59)',
          backgroundAttachment: 'fixed'

        }}
      >
      <div className=' absolute'
        style={{
          background: 'rgba(0, 0, 0, 0.59)',
          height: '100%',
          width: '100%'
        }}
      ></div>
</div>
    </>
  );
};

export default ParallaxBackground;
