import React from "react";
import useBreakPoints from "../Hooks/useBreakPoints";

const pointers = [
    {
        color: "#de5221",
        text: "GMADA Allotted 4.05 Acres Land"
    },
    {
        color: "#d92670",
        text: "Showrooms, Offices Studio / Service Apartments, Retail Entertainment",
    },
    {
        color: "#e58a2b",
        text: "5 lacs+ catchment of affluent residents",
    },
    {
        color: "#00999c",
        text: "More than 1 lac workforce commuting everyday in the surrounding area",
    },
    {
        color: "#78ba30",
        text: "One stop destination for people residing in the nearby sectors.",
    },
    {
        color: "#bd2973",
        text: "Natural footfall from nearby Corporate Offices, Industrial Units."
    },
]

export const Location = () => {
    const { viewportWidth } = useBreakPoints()



    return (
        <>
            <div id="location" className={`w-[100%] h-[100%]  py-[2vh] xs:pt-0 xs:pb-[2vh] gap-[2vw] bg-white
         ${viewportWidth > 1850 ? 'pl-[15vw] pr-[15vw]' :
                    viewportWidth > 1750 ? 'pl-[14vw] pr-[17vw]' :
                        viewportWidth > 1650 ? 'pl-[12vw] pr-[14vw]' :
                            viewportWidth > 1599 ? 'pl-[12vw] pr-[14vw]' :
                                'px-[4vw]  xs:px-[12vw] '}
                `}>

                <div className={`  w-[100%] flex flex-col xs:flex-col gap-[0] h-[100%]`}
                    style={{
                        color: 'rgb(15, 15, 15)',
                        alignItems: 'stretch',
                    }} >

                    <div className="w-[100%] h-auto xs:h-[226px] xs:w-[320px]">
                        <img
                            className="w-[100%] h-auto xs:h-[226px] xs:w-[320px] object-contain bg-no-repeat"
                            src="/images/map.png"
                            alt="location"
                        
                        />

                    </div>

                    <div className="flex xs:flex-col xs:gap-y-[20px] w-[100%] h-[180px] xs:h-[100%]  gap-x-[1%] justify-center items-center">
                        {
                            pointers.map((item, index) => (
                                <div
                                    key={index}
                                    className={`px-[20px] w-[100%] h-[100%] xs:h-[175px] xs:w-[200px] text-center flex items-center rounded-br-[40px] justify-center  text-white`}
                                    style={{
                                        backgroundColor: item.color,
                                        boxShadow: "0 2px 4px -1px rgba(0, 0, 0, .06), 0 4px 5px 0 rgba(0, 0, 0, .06), 0 1px 10px 0 rgba(0, 0, 0, .08)"
                                    }}
                                >
                                    {item.text}
                                </div>
                            ))
                        }
                    </div>

                </div>




            </div>
        </>
    );
};