import React from "react";
import useBreakPoints from "../Hooks/useBreakPoints";

export const About = () => {
  const { viewportWidth } = useBreakPoints()
 
  return (
    <>
      <div id="about-projects" className={` w-[100%] h-[100%]  pb-[8vh] xs:pt-[5vh]  xs:pb-[0] bg-[#f8f8f8]  flex xs:flex-col gap-[2vw]
         ${viewportWidth > 1850 ? 'pl-[15vw] pr-[15vw]' :
          viewportWidth > 1750 ? 'pl-[14vw] pr-[17vw]' :
            viewportWidth > 1650 ? 'pl-[12vw] pr-[14vw]' :
              viewportWidth > 1599 ? 'pl-[12vw] pr-[14vw]' :
                'px-[4vw] xs:px-[12vw] '}
                `}>

        <div className={`  w-[100%] flex xs:flex-col gap-[2vw] xs:gap-[5vw] h-[100%]`}
          style={{
            color: 'rgb(15, 15, 15)',
            alignItems: 'stretch',
          }} >

          <div className="w-[45%] xs:w-[100%] pt-[6vh] xs:pt-[3vh] h-[100%] xs:order-2 xs:mt-[0] flex flex-col justify-between">

            <div>
              <div className="text-[20px] xs:text-[20px] font-[Lato] font-[300] text-[#f2195d]">
                <strong>Bold. Enticing. Exhilarating</strong>
              </div>

              <div className="text-[36px] font-[300] xs:text-[20px] font-[Lato]  mt-[-4px] mb-[-3px] text-[#1f9b9d]">Fintech Square, Mohali.</div>

              <div className="text-[36px] font-[400] xs:text-[20px] font-[Lato] text-[#0F0F0F]">Live The Perfect Life.</div>

              <div className='flex mt-[15px]'>
                <div className='block bg-[#f2195d]  h-[3px] w-[40px] z-[100]'
                  style={{
                    background: 'rgba(224, 188, 112, 1)',
                  }}
                />
                <div className='block  bg-[#e2e0e0] h-[3px] w-[40px] z-[100]'
                  style={{
                    background: 'rgba(224, 188, 112, 1)',
                  }}
                />
              </div>
            </div>

            <div>
              <div className=" py-[10px] flex flex-col gap-[10px] mb-[20px] ">
                <p className="font-[300] text-[17px] text-justify text-[#433f3d] font-[Lato]  leading-loose">
                  Located at the prestigious CP-2, Industrial Focal Point, Phase - 8A, Sector - 75, Mohali, Punjab, it is all set to salute your aspirations and take your ambition to the next level. It is set to be the next landmark in the cityscape. It will not just be a commercial destination but will be the first-ever international commercial hub of Tri-city.
                </p>
                <p className="font-[300] text-[17px] text-justify text-[#433f3d] font-[Lato] leading-loose">
                  Fintech square will be one of the region’s first commercial buildings with lavishing office spaces, retail stores, food courts, co-working spaces, showrooms, gyms, and serviced apartments all in one place in a location close to IT city, the Judicial court complex and several other popular hotspots of Mohali. With energy-efficient glass façades, beautiful boulevards with shrubs, majestic fountains, and green landscaped areas, it will be a desirable commercial space in Punjab. The contemporary multi-storey retail stores, glass-front showrooms, and prominent locations will make it the perfect marketplace for retail brands.
                </p>
              </div>
            </div>
          </div>

          <div className="w-[55%] xs:w-[100%]  xs:h-[300px] h-[600pcx xs:order-1 ">
            <div className="w-[100%] h-[100%] xs:h-[100%] relative ">
              <div className="absolute top-0 left-0 w-[100%] h-[100%] z-[999] ">
                <img
                  className="w-[100%] h-[100%] object-cover rounded-bl-[80px] xs:rounded-bl-[0]"
                  src="/images/about_section.jpg"
                  alt="about"
                />
              </div>
            </div>

          </div>

        </div>




      </div>
    </>
  );
};