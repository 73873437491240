import React from 'react';
import ParallaxBackground from '../Parallax/ParallaxBackground';
import ParallaxPage from '../Parallax/ParallaxPage';


const Home = () => {
  return (
    <div>
      <ParallaxBackground />
      <ParallaxPage />
    </div>
  );
};

export default Home;
