/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import useBreakPoints from "../Hooks/useBreakPoints";
import { useNavigate } from "react-router-dom";

import axios from "axios"

export const Form = () => {
    const { viewportWidth } = useBreakPoints();
    const [formError, setFormError] = useState({});
    const [utmSource, setUtmSource] = useState("google");
    const [disableButton, setDisableButton] = useState(false)
    const subject = "Fintech Square Leads";
    const heading = "Fintech Square Leads"
    const googleSheetUrl = `https://script.google.com/macros/s/AKfycbxmqR4rM7TfZSU5WhwfopbeRNRYghza6JGWHjAElYgeQ1ACELi-Vqk9aeQ5e1HU6QW8TA/exec`
    const navigate = useNavigate();


    const currentDate = new Date().toISOString().slice(0, 10);

    const initialValues = {
        name: "",
        email: "",
        mobile: "",
        city: "",
        tentativeBudget: "",
        interestedIn: "",
        subject: subject,
        date: currentDate,
        heading: heading,
        googleSheetUrl: googleSheetUrl,
        leadSource: utmSource
    };
    const [data, setData] = useState(initialValues);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const utm_source = urlParams.get("utm_source") || "google";

        setUtmSource(utm_source);

        setData((prevData) => ({
            ...prevData,
            leadSource: utm_source
        }));
    }, []);

    const validate = (values) => {
        const error = {};

        if (!values.name) error.name = "name is required";
        else if (!values.email) error.email = "email is required";
        else if (
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
        )
            error.email = "email is invalid";
        else if (!values.mobile) error.mobile = "phone number is required";
        else if (!/^[0-9\b]+$/.test(values.mobile))
            error.mobile = "enter number only";
        else if (values.mobile.length !== 10)
            error.mobile = "Phone number must be exactly 10 digits";
        else if (!values.city) error.city = "city is required";
        else if (!values.tentativeBudget)
            error.tentativeBudget = "interestedIn is required";
        else if (!values.interestedIn)
            error.interestedIn = "interestedIn is required";
        return error;
    };



    const sendMail = (e) => {
        e.preventDefault();
        setDisableButton(true);

        const msg = validate(data);
        setFormError(msg);
        if (Object.keys(msg).length > 0) {
            setDisableButton(false);
            return;
        }

        const formData = new FormData();
        formData.append("currentDate", currentDate);
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("mobile", data.mobile);
        formData.append("city", data.city);
        formData.append("interestedIn", data.interestedIn);
        formData.append("tentativeBudget", data.tentativeBudget);
        formData.append("utmSource", utmSource);

        axios({
            url: googleSheetUrl,
            method: "POST",
            data: formData,

        })
            .then(() => {
                setData(initialValues);
                navigate("/thankyou");
            })
            .catch((err) => {
                setFormError(err)
                console.log(err);
                setDisableButton(false);
            });

    };

    // const sendMail = (e) => {
    //     e.preventDefault();
    //     setDisableButton(true);
    //     const msg = validate(data);
    //     setFormError(msg);
    //     if (Object.keys(msg).length > 0) {
    //         setDisableButton(false);
    //         return;
    //     }
    //     fetch(mailURL, {
    //         method: "POST",
    //         // mode: "no-cors",
    //         headers: {
    //             "Content-Type": "application/json",
    //             // "Access-Control-Allow-Origin": "*",
    //         },
    //         body: JSON.stringify(data),
    //     })
    //         .then(() => {
    //             setData(initialValues);
    //             // setMail("Request sent successfully");
    //             navigate("/thankyou");
    //             // openModal()
    //         })
    //         .catch((error) => {
    //             alert("Something went wrong please try again");
    //             console.log(error);
    //             setDisableButton(false);
    //         });
    // };

    const change = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    return (
        <>
            <div
                id="form"
                className={` bg-white h-[100%]  py-[3vh] xs:py-[4vh] w-[100%] mx-auto
         ${viewportWidth > 1850 ? 'pl-[15vw] pr-[15vw]' :
                        viewportWidth > 1750 ? 'pl-[14vw] pr-[17vw]' :
                            viewportWidth > 1650 ? 'pl-[12vw] pr-[14vw]' :
                                viewportWidth > 1599 ? 'pl-[12vw] pr-[14vw]' :
                                    'px-[4vw] xs:px-[14vw]'
                    }
        `}
            >
                {/* <div
          className={`${erro ? 'block' : 'hidden'} text-center w-[100%]`}
          style={{ marginBottom: '25px', color: 'red' }}
        >
          {erro ?? erro}
        </div>

        <div
          className={`${mail ? 'block' : 'hidden'} text-center w-[100%]`}
          style={{ marginBottom: '5px', color: 'green' }}
        >
          {mail ?? mail}
        </div> */}
                <div className="w-[100%]  gap-[20px] flex-nowrap flex xs:flex-col h-[100%] border-[1px] border-solid border-[#eceaea] p-[20px]" >
                    <div className="w-[80%] h-[100%] xs:w-[100%]">
                        <form
                            className="grid grid-cols-3 xs:grid-cols-1    gap-4 w-[100%]"
                            onSubmit={(e) => sendMail(e)}
                        >
                            <div className="xs:w-[100%]">
                                {/* <label
                                className="block xs:text-[16px] text-[15px] font-[Lato] font-[300] text-[#373435] mb-1 "
                                htmlFor="name"
                            >
                                Full Name *
                            </label> */}
                                <input
                                    id="name"
                                    type="text"
                                    required
                                    name="name"
                                    className="w-full text-[14px] px-[15px]  h-[50px] text-[#888585]  bg-[#f8f8f8]"
                                    placeholder="Your Name"
                                    onChange={(e) => change(e)}
                                    value={data.name}
                                />
                                <div className="text-[11px] xs:text-[10px] ml-[10px] text-red-500">
                                    {formError.name}
                                </div>
                            </div>

                            <div className="xs:w-[100%]">
                                {/* <label
                                className="block xs:text-[16px] text-[15px] font-[Lato] font-[300] text-[#373435] mb-1"
                                htmlFor="mobile"
                            >
                                Phone Number *
                            </label> */}
                                <input
                                    id="mobile"
                                    type="tel"
                                    name="mobile"
                                    className="w-full text-[14px] px-[15px]  h-[50px] text-[#888585]  bg-[#f8f8f8]"
                                    placeholder="Phone Number"
                                    required
                                    onChange={(e) => change(e)}
                                    value={data.mobile}
                                />
                                <div className="text-[11px] xs:text-[10px] ml-[10px] text-red-500">
                                    {formError.mobile}
                                </div>
                            </div>

                            <div className="xs:w-[100%]">
                                {/* <label
                                className="block xs:text-[16px] text-[15px] font-[Lato] font-[300] text-[#373435] mb-1"
                                htmlFor="email"
                            >
                                Email *
                            </label> */}
                                <input
                                    id="email"
                                    type="email"
                                    name="email"
                                    className="w-full text-[14px] px-[15px]  h-[50px] text-[#888585]  bg-[#f8f8f8]"
                                    placeholder="Email Id"
                                    required
                                    onChange={(e) => change(e)}
                                    value={data.email}
                                />
                                <div className="text-[11px] xs:text-[10px] ml-[10px] text-red-500">
                                    {formError.email}
                                </div>
                            </div>



                            <div className="xs:w-[100%]">
                                {/* <label
                                className="block xs:text-[16px] text-[15px] font-[Lato] font-[300] text-[#373435] mb-1"
                                htmlFor="city"
                            >
                                City *
                            </label> */}
                                <input
                                    id="city"
                                    type="text"
                                    name="city"
                                    className="w-full text-[14px] px-[15px]  h-[50px] text-[#888585]  bg-[#f8f8f8]"
                                    placeholder="City"
                                    required
                                    onChange={(e) => change(e)}
                                    value={data.city}
                                />
                                <div className="text-[11px] xs:text-[10px] ml-[10px] text-red-500">
                                    {formError.city}
                                </div>
                            </div>

                            <div className="xs:w-[100%]">
                                {/* <label
                                className="block xs:text-[16px] text-[15px] font-[Lato] font-[300] text-[#373435] mb-1"
                                htmlFor="tentativeBudget"
                            >
                               Tentative Budget?
                            </label> */}
                                <select
                                    id="tentativeBudget"
                                    name="tentativeBudget"
                                    className="w-full text-[14px] px-[15px]  h-[50px] text-[#888585]  bg-[#f8f8f8]"
                                    placeholder="Tentative Budget?"
                                    onChange={(e) => change(e)}
                                    value={data.tentativeBudget}
                                >
                                    <option value="">Tentative Budget?</option>
                                    <option value="1 Cr">1 Cr.</option>
                                    <option value="2 Cr">2 Cr.</option>
                                    <option value="3 Cr">3 Cr.</option>
                                    <option value="Above 3 Cr">Above 3 Cr.</option>

                                </select>
                                <div className="text-[11px] xs:text-[10px] ml-[10px] text-red-500">
                                    {formError.tentativeBudget}
                                </div>
                            </div>

                            <div className="xs:w-[100%]">
                                {/* <label
                                className="block xs:text-[16px] text-[15px] font-[Lato] font-[300] text-[#373435] mb-1"
                                htmlFor="interestedIn"
                            >
                                Interested In? *
                            </label> */}
                                <select
                                    id="interestedIn"
                                    name="interestedIn"
                                    className="w-full text-[14px] px-[15px]  h-[50px] text-[#888585]  bg-[#f8f8f8]"
                                    placeholder="Interested In?"
                                    onChange={(e) => change(e)}
                                    value={data.interestedIn}
                                >
                                    <option value="">Interested In?</option>
                                    <option value="Residential Plots">Residential Plots</option>
                                    <option value="Commercial">Commercial</option>
                                </select>
                                <div className="text-[11px] xs:text-[10px] ml-[10px] text-red-500">
                                    {formError.interestedIn}
                                </div>
                            </div>


                        </form>
                    </div>
                    <div className="w-[20%] h-auto xs:h-max  xs:w-[100%]">
                        <div className="w-[100%] h-[100%]">
                            <button
                                disabled={disableButton}
                                type="submit"
                                onClick={(e) => sendMail(e)}
                                className={`w-[100%] h-[100%] xs:whitespace-nowrap  bg-[#1f9b9d] uppercase flex justify-center items-center text-center font-[Montserrat]  text-[20px] xs:text-[16px] text-[#fff] px-6 py-2 xs:py-4  hover:bg-[#000]  transition-all duration-100 ease-linear ${disableButton ? "cursor-not-allowed" : "cursor-pointer"} `}
                            >
                                Download brochure
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-[100%] h-auto sm:hidden">
                    <img alt="dummy" src="/images/form_shadow.png" className="w-[100%] h-[25px] bg-cover" />
                </div>


            </div>
        </>
    );
};
