import { useState, useEffect } from 'react';

const useBreakPoints = () => {
    const [viewportHeight, setViewportHeight] = useState(0);
    const [viewportWidth, setViewportWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
            setViewportWidth(window.innerWidth);
        };

        handleResize(); // Set initial values

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return {
        viewportWidth,
        viewportHeight
    };
}

export default useBreakPoints;
