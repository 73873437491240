
import React from "react";
import { Link } from "react-router-dom";

export const ThankYou = () => {
    return (
        <section className={`pt-[4rem] relative h-[100vh] flex justify-center bg-[#f7f5ec]`}>
            <div className="container p-5">
                <div className="row text-center">
                    <div className={`col-md-6 cursor-pointer xs:flex xs:justify-center`}>
                        <Link to="/">
                            <div className="w-[315px] h-[80px]">
                                <img
                                    alt="logo"
                                    className="logo1 w-[100%] h-[100%]"
                                    src="/images/Fintech_Square.png"
                                />
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 mt-4">
                        <span className="font-[300] font-[Lato] xs:text-[20px]">Call Us On : +91-7837377070
                        </span>
                    </div>
                    <div className="col-md-12 mt-[5vh] pd-t2 mb-4">
                        <span className={`text-[90px] leading-[58px] xs:text-[70px] xs:leading-[58px] font-[Montserrat] font-[400]`}>All Set..!</span>
                    </div>
                    <div className="col-md-12 mb-4">
                        <span className={`text-[20px] leading-[22px]  xs:text-[30px] xs:leading-[43px] font-[Lato] font-[300]`}>
                            Thank You For Sharing Your Information
                        </span>
                    </div>
                    <div className="col-md-12 mb-4">
                        <span className={` text-[20px] xs:text-[20px] leading-[22px] font-[Lato] font-[300]`}>
                            Our Team will call you shortly to share all the details about
                            Fintech.{" "}
                        </span>
                    </div>

                    <div className="col-md-12 mt-5">
                        {/* <Link href="/assets/pdf/fintech_brochure.pdf"> */}
                            <a href="/assets/pdf/fintech_brochure.pdf" target="_blank" rel="noreferrer" className="flex justify-center">
                            <div
                                        className="font-[300] xs:w-max mt-[20px] uppercase text-[20px] xs:text-[18px] px-[20px] flex justify-center items-center border-[1px] border-solid border-[#23344d] bg-[#23344d] hover:bg-[#1b2c49] text-white rounded-[5px] py-[7px] tracking-[1px] cursor-pointer transition-all ease-linear w-max font-[Lato] "
                                    >
                                        Download brochure
                                    </div>
                            </a>
                        {/* </Link> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

