import React from 'react';
import useBreakPoints from "../Hooks/useBreakPoints";


export const Navbar = () => {
    const { viewportWidth } = useBreakPoints();

    return (
        <>
            <div
                className={`w-[100%]  flex justify-between   bg-white mt-[55px] xs:py-[20px]
         ${viewportWidth > 1850 ? 'pl-[15vw] pr-[15vw]' :
                        viewportWidth > 1750 ? 'pl-[14vw] pr-[17vw]' :
                            viewportWidth > 1650 ? 'pl-[12vw] pr-[14vw]' :
                                viewportWidth > 1599 ? 'pl-[12vw] pr-[14vw]' :
                                    'px-[4vw] xs:px-0 xs:w-[100vw] xs:justify-center xs:items-center xs:mt-[120px]'}
                `}>
                <div className='flex justify-center items-center gap-[30px] text-black  text-[16px] '>
                    <div className='w-[225px] h-[120px] xs:w-[226px] xs:h-[80px] '>
                        <a href="/">
                            <img
                            alt="dummy"
                                src="/images/VRS.png"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        </a>
                    </div>
                </div>
                <div className='flex justify-center gap-[30px]  py-3 xs:hidden'>
                    <ul className='flex gap-[40px] items-center   text-[12px] tracking-[1.2px]'>
                        <a href="#home">
                            <li className='hover:text-[#f2195d] text-[14px] font-[500] cursor-pointer text-[#000]  capitalize'>
                                {/* <strong> */}
                                Home
                                {/* </strong> */}
                            </li>
                        </a>
                        <a href="#about-projects">
                            <li className='hover:text-[#f2195d]  text-[14px] font-[500] cursor-pointer text-[#000]  capitalize'>
                                {/* <strong> */}
                                About Project
                                {/* </strong> */}
                            </li>
                        </a>
                        <a href="#features">
                            <li className='hover:text-[#f2195d]  text-[14px] font-[500] cursor-pointer text-[#000]  capitalize'>
                                {/* <strong> */}
                                Features
                                {/* </strong> */}
                            </li>
                        </a>
                        <a href="#location">
                            <li className='hover:text-[#f2195d]  text-[14px] font-[500] cursor-pointer text-[#000]  capitalize'>
                                {/* <strong> */}
                                Location
                                {/* </strong> */}
                            </li>
                        </a>
                        <a href="#product-mix">
                            <li className='hover:text-[#f2195d]  text-[14px] font-[500] cursor-pointer text-[#000]  capitalize'>
                                {/* <strong> */}
                                Product Mix
                                {/* </strong> */}
                            </li>
                        </a>
                        <a href="#floor-plans">
                            <li className='hover:text-[#f2195d]  text-[14px] font-[500] cursor-pointer text-[#000]  capitalize'>
                                {/* <strong> */}
                                Floor Plans
                                {/* </strong> */}
                            </li>
                        </a>
                    </ul>
                </div>
            </div>
        </>
    )
}

