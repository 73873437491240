import React from 'react'
import useBreakPoints from "../Hooks/useBreakPoints";


export const StickyHeader = () => {
  const { viewportWidth } = useBreakPoints();

  return (
    <>
      <div className='w-[100%] h-[4px] '>
        <img
        alt="dummy"
        src="/images/line_header.png" 
        style={{
          width:'100%',
          height:'100%',
          objectFit:'cover'
        }}
        />
      </div>
      <div
        style={{
          backgroundColor: "rgba(244, 244, 244, 1)"
        }}
        className={`w-[100%]   flex justify-between absolute top-[4px] h-[55px] z-[9999] xs:flex-col  xs:justify-center xs:items-center
         ${viewportWidth > 1850 ? 'pl-[15vw] pr-[15vw]' :
            viewportWidth > 1750 ? 'pl-[14vw] pr-[17vw]' :
              viewportWidth > 1650 ? 'pl-[12vw] pr-[14vw]' :
                viewportWidth > 1599 ? 'pl-[12vw] pr-[14vw]' :
                  'px-[2.5vw] xs:px-0 xs:w-[100vw] xs:h-max '}
        `}>
        <div
          style={{ color: "rgba(244, 244, 244, 1)" }}
          className='flex justify-center gap-[30px] xs:gap-[20px]  py-3  text-[16px] xs:text-[20px] font-[Montserrat] font-[400]'>
          Call : +91-7837377070
        </div>
        <div className='flex justify-center gap-[30px] xs:gap-[20px] text-[#000] py-3  text-[16px] xs:text-[20px] font-[Montserrat] '>
          <div className='py-[8px] flex'>
            <div className='font-[500]'>
              Call :
            </div>
            <div className='pl-[10px] font-[500]'>
              {/* <a href='/'> */}
                +91-7837377070
              {/* </a> */}
            </div>
          </div>
        </div>
        <div className='flex xs:hidden justify-center gap-[30px] xs:gap-[20px] text-[#242b2a] py-3 text-[14px] xs:text-[12px] font-[Montserrat] font-[400]'>
          <div className='py-[8px] flex'>
            PBRERA-SAS81-PC0180  | www.rera.punjab.gov.in
          </div>
        </div>

        <div className='xs:flex  xs:flex-col top-2 right-5 hidden absolute  gap-[1px]  '>
          <div className='text-[#242b2a] xs:text-[12px] font-[Lato] font-[400]'>
            PBRERA-SAS81-PC0180
          </div>
          <div className='text-[#242b2a] xs:text-[12px] font-[Lato] font-[400] mt-[-4px]'> www.rera.punjab.gov.in</div>
        </div>
      </div>
    </>
  )
}

