import React from "react";
import useBreakPoints from "../Hooks/useBreakPoints";

const Items = [
    {
        topSection: {
            h1: "Retail",
            h2: "Indulge in a Never Before",
            h3: "Seen Shopping Scene.",
        },
        content: "The residents of Tricity is not far from a sumptuous shopping scene as Fintech Square has a mix of Double Height Showrooms (DHS) and Premium Retail, crafted professionally for world-class retail brands and independent labels. Once here, the visitor will have a wide range of showrooms and brands to explore, giving them the much needed level of satisfaction and a plethora of choices to choose from.",
        pointers: ["Double Height Showrooms", "Premium Retail"],
        order1: 1,
        order2: 2,
        borderRadius: "rounded-bl-[80px] xs:rounded-bl-[50px] ",
        imagePath: "/images/section_1.jpg",
    },
    {
        topSection: {
            h1: "Entertainment ",
            h2: "Experience Gourmet Delicacies",
            h3: "& Leisure in a Novel Way.",
        },
        content: "Millennials today are always on the lookout for a place where they can unwind, relax and entertain themselves to their heart’s content. Fintech Square will become that millennial hotspot as it will house leading food chains and gourmet restaurants. The project will also have a microbrewery, attracting people in large numbers. So, whether one wish to enjoy some street style food or looking for a fine dining experience, Fintech Square will become their loved go-to place.",
        pointers: ["Food Court ", "Micro-Breweries", "Fine Dining Restaurants"],
        order1: 2,
        order2: 1,
        borderRadius: "rounded-br-[80px]  xs:rounded-br-[50px]",
        imagePath: "/images/section_2.jpg",
    },
    {
        topSection: {
            h1: "Work",
            h2: "Double Your Passion",
            h3: "For Work.",
        },
        content: "These smartly designed offices of Fintech Square are built for smart executives. A centre of excellence and perfection, Fintech Square is a perfect office space for any business. The uniquely crafted, spacious and contemporary offices have elegant sky-deck spaces to take your imagination to the next level. Based on the evolving nature of business, we have created one-of-a-kind flexible workspaces.",
        pointers: ["Terrace Offices", "Offices With Balcony"],
        order1: 1,
        order2: 2,
        borderRadius: "rounded-bl-[80px]  xs:rounded-bl-[50px]",
        imagePath: "/images/section_3.jpg",
    },
    {
        topSection: {
            h1: "Live",
            h2: "Double Your Joy of Living",
            h3: "Get Your Ultra-Luxury Living.",
        },
        content: "Fintech Square is home to some of the most contemporary service apartments. It brings a new standard of luxury. The posh boutique is designed in the style of a private European mansion with a garden-view restaurant, signature swimming pool, and an exclusive fitness centre for business travellers. The wide range of amenities, innovative facilities, and personalized services brings you an aura of satisfaction and pleasure.",
        pointers: ["Studio / Service Apartments"],
        order1: 2,
        order2: 1,
        borderRadius: "rounded-br-[80px]  xs:rounded-br-[50px]",
        imagePath: "/images/section_4.jpg",
    },
];

export const Product = () => {
    const { viewportWidth } = useBreakPoints();


    return (
        <>
            <div
                id="product-mix"
                className={`pt-[15vh] w-[100%] h-[100%] pb-[15vh] xs:pt-[5vh] xs:pb-[5vh] bg-[#f8f8f8] flex  gap-[2vw]
         ${viewportWidth > 1850
                        ? "pl-[15vw] pr-[15vw]"
                        : viewportWidth > 1750
                            ? "pl-[14vw] pr-[17vw]"
                            : viewportWidth > 1650
                                ? "pl-[12vw] pr-[14vw]"
                                : viewportWidth > 1599
                                    ? "pl-[12vw] pr-[14vw]"
                                    : "px-[4vw] xs:px-[12vw]"
                    }`}
            >
                <div className={`w-[100%] flex flex-col gap-[10vw] h-[100%]`}>
                    {Items.map((item, index) => (
                        <div
                            key={index}
                            className={`w-[100%]  h-[100%] gap-[30px] xs:gap-[10px] flex xs:flex-col `}

                        >
                            <div
                                className={`w-[45%] xs:w-[100%]  h-[100%] xs:order-2 xs:mt-[30px] flex flex-col justify-between`}
                                style={{ order: item.order1 }}
                            >
                                <div>
                                    <div className="text-[20px] xs:text-[20px] font-[Lato] font-[300] text-[#f2195d]">
                                        <strong>{item.topSection.h1}</strong>
                                    </div>

                                    <div className="text-[36px] font-[300] xs:text-[26px] xxs:text-[24px] whitespace-nowrap font-[Lato] mb-[-4px] text-[#0F0F0F]">
                                        {item.topSection.h2}
                                    </div>

                                    <div className="text-[36px] font-[400] xs:text-[26px] xxs:text-[24px] whitespace-nowrap font-[Lato] text-[#0F0F0F]">
                                        {item.topSection.h3}
                                    </div>
                                </div>

                                <div className="py-[20px] flex flex-col gap-[20px]">
                                    <p className="font-[300] text-[17px] text-justify text-[#433f3d] font-[Lato]  xs:leading-normal leading-[1.7]">
                                        {item.content}
                                    </p>
                                </div>

                                <div className="w-[100%] flex gap-x-[30px] xs:gap-x-[10px] xxs:gap-x-[8px] whitespace-nowrap">
                                    {item.pointers.map((point, i) => (
                                        <div
                                            key={i}
                                            className="text-[#551d63] font-[Lato] xs:tracking-normal xs:text-[12px]  font-[400] text-[18px]"
                                        >
                                            &gt; {point}
                                        </div>
                                    ))}
                                </div>

                                <a href="#form">
                                    <div
                                        className="font-[300] xs:w-[100%] mt-[20px] uppercase text-[20px] xs:text-[18px] px-[20px] flex justify-center items-center border-[1px] border-solid border-[#23344d] bg-[#23344d] hover:bg-[#1b2c49] text-white rounded-[5px] py-[7px] tracking-[1px] cursor-pointer transition-all ease-linear w-max font-[Lato] "
                                    >
                                        Download brochure
                                    </div>
                                </a>
                            </div>

                            <div
                                style={{ order: item.order2 }}
                                className="w-[55%] xs:w-[100%] xs:h-[230px] h-[max] xs:order-1">
                                <div className="w-[100%] h-[100%] xs:h-[100%] relative">
                                    <div className="absolute top-0 left-0 w-[100%] h-[100%] z-[999]">
                                        <img
                                            className={`w-[100%] h-[100%] object-cover ${item.borderRadius}`}
                                            src={item.imagePath}
                                            alt="about"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
