import React from 'react';
import { StickyHeader } from '../Components/StickyHeader';
import { Navbar } from '../Components/Navbar';
import { Banner } from '../Components/Banner';
import { About } from '../Components/About';
import { Features } from '../Components/Features';
import { Location } from '../Components/Location';
import Footer from '../Components/Footer';
import { Product } from '../Components/Product';
import { FloorPlans } from '../Components/FloorPlans';
import { FloorPlansGroup } from '../Components/FloorPlansGroup';
import { Form } from '../Components/Form';

const ParallaxPage = () => {
  return (
    <div>
      <div className="relative z-10 min-h-screen flex flex-col  text-white bg-opacity-50 overflow-x-hidden">
        <StickyHeader />
        <Navbar />
        <Banner />
        <Form />
        <About />
        <Features />
       <Location />
       <Product />
       <FloorPlans />
       <FloorPlansGroup />
        <Footer />

      </div>

    </div>
  );
};

export default ParallaxPage;
