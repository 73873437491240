import React from "react";
import useBreakPoints from "../Hooks/useBreakPoints";

export const Features = () => {
  const { viewportWidth } = useBreakPoints();

  return (
    <>
      <div
        id="features"
        className={`about-details-page w-[100%] h-[100%] pb-[5vh] xs:pb-0 bg-white
         ${viewportWidth > 1850 ? 'pl-[15vw] pr-[15vw]' :
            viewportWidth > 1750 ? 'pl-[14vw] pr-[17vw]' :
              viewportWidth > 1650 ? 'pl-[12vw] pr-[14vw]' :
                viewportWidth > 1599 ? 'pl-[12vw] pr-[14vw]' :
                  'px-[4vw] xs:pt-[0] xs:px-[12vw]'}
       `}
      >

        <div className={`w-[100%] flex xs:flex-col gap-[1vw]  xs:gap-[3vw] h-[100%]`}>

          <div className="w-[45%] h-[max] relative flex justify-end items-end xs:w-[100%] xs:h-[50vh]">
            <div className="absolute top-0 left-0 w-[100%] h-[92%] xs:h-[88%] xxs:h-[84%]">
              <img
                className="w-[100%] h-[100%] object-cover rounded-br-[80px]"
                src="/images/list_section.jpg"
                alt="features"
              />
            </div>
            <div className="flex w-[100%] z-[999] gap-[20px] justify-between items-center mt-[15px]">
              <div className="flex gap-0 w-[40%]">
                <div className="bg-[#f2195d] h-[5px] w-[50%] z-[100] flex items-center justify-center" />
                <div className="bg-[#e2e0e0] h-[5px] w-[50%] z-[100] flex items-center justify-center" />
              </div>

              <a href="#form">
                <div
                  className="font-[300] font-[Lato]  xs:mt-[20px] uppercase text-[20px] xs:text-[18px] px-[20px] xs:px-[15px] flex justify-center items-center border-[1px] border-solid border-[#23344d] bg-[#23344d] hover:bg-[#1b2c49] text-white rounded-[5px] py-[7px] tracking-[1px] cursor-pointer transition-all ease-linear w-max">
                  Download brochure
                </div>
              </a>
            </div>
          </div>

          <div className="w-[55%] text-black bg-white h-[100%] xs:w-[100%]">
            <div className="mt-[30px] text-center uppercase mb-[30px] text-[30px]  xs:text-[25px] font-[Lato] font-[400] text-[#23344d] xs:mb-[1px]">
              Features & amenities
            </div>
            <div className="h-auto w-[100%] xs:hidden ">
              <img
                className="w-[100%] h-auto object-cover bg-no-repeat"
                src="/images/ameneties.jpg"
                alt="ameneties"
              />
            </div>



            <div className="xs:flex flex-col gap-[6vh] px-[7vw] py-0  hidden">
              <div className="h-auto w-[100%] ">
                <img
                  className="w-[100%] h-auto object-cover bg-no-repeat"
                  src="/images/list_1.png"
                  alt="features"
                />
              </div>

              <div className="h-auto w-[100%]  ">
                <img
                  className="w-[100%] h-auto object-cover bg-no-repeat"
                  src="/images/list_2.png"
                  alt="features"
                />
              </div>
              <div className="h-auto w-[100%]  ">
                <img
                  className="w-[100%] h-auto object-cover bg-no-repeat"
                  src="/images/list_3.png"
                  alt="features"
                />
              </div>
            </div>
          </div>

        </div>


      </div>
    </>
  );
};
