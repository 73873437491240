import React, { useState } from 'react'
import useBreakPoints from '../Hooks/useBreakPoints'

export const Banner = () => {
    const { viewportHeight, viewportWidth } = useBreakPoints()

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            closeModal();
        }
    };

    return (
        <>
            <div id="home" className={`flex flex-col justify-center items-center w-[100%]  
                ${viewportWidth < 1400 && viewportWidth > 1300 && viewportHeight < 720 && viewportHeight > 550 ? "py-[22vh]" : "lg:py-[18vh] py-[15vh] xs:py-[12vh]"}
                `}
                style={{
                    backgroundColor: `${isModalOpen ? '#777777b3' : 'transparent'}`,
                }}
            >
                <div className='w-[350px] h-[100px] xs:w-[220px] xs:h-[63px]'>
                    <img
                        alt="dummy"
                        src="/images/logo_fintech.png"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </div>

                <div className='font-[600] font-[lato] text-[50px] xs:text-[30px] xs:font-[400]  xs:mt-[10px] uppercase text-[#ffeac5]'>
                    Sector 75 , Mohali
                </div>

                <div className='flex mt-[15px] xs:mb-[10px]'>
                    <div className='block bg-[#f2195d] h-[3px] w-[40px] z-[100]'
                        style={{
                            background: 'rgba(224, 188, 112, 1)',
                        }}
                    />
                    <div className='block b bg-[#ffffff]  h-[3px] w-[40px] z-[100]'
                        style={{
                            background: 'rgba(224, 188, 112, 1)',
                        }}
                    />
                </div>

                <div className='font-[300] xs:hidden font-[Lato] text-[20px] pt-[10px] text-[#eeeeee] flex  px-[2px] justify-between gap-[5px]'>
                    <div> SHOWROOMS</div>
                    <div>|</div>
                    <div>OFFICES</div>
                    <div>|</div>
                    <div>STUDIO / SERVICE APARTMENTS</div>
                    <div>|</div>
                    <div>CO-WORKING SPACES</div>
                    <div>|</div>
                    <div>RETAIL</div>
                    <div>|</div>
                    <div>ENTERTAINMENT</div>
                </div>

                <div className='font-[300] xs:block hidden text-center font-[Lato] text-[18px]  pt-[10px] text-[#eeeeee]  px-[15vw] justify-between gap-[5px]'>
                    SHOWROOMS | OFFICES |  STUDIO / SERVICE APARTMENTS | CO-WORKING SPACES | RETAIL | ENTERTAINMENT
                </div>

                <div onClick={openModal} className={`border-[1px] border-solid rounded-[100%] bg-[#ffffff] px-[24px] py-[24px] xs:px-[18px] xs:py-[18px] cursor-pointer transition-all ease-linear
                 ${viewportWidth < 1400 && viewportWidth > 1300 && viewportHeight < 720 && viewportHeight > 550 ? "mt-[8vh]" : "lg:mt-[15vh] mt-[5vh] xs:mt-[3vh]"}
                `}
                    style={{
                        borderColor: 'rgb(255, 255, 255)'
                    }}
                >
                    <div className='w-[20px] h-[20px] ]'>
                        <img
                            alt="dummy"
                            src="/images/play.png"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </div>
                </div>

                {isModalOpen && (
                    <div
                        onClick={handleOutsideClick}
                        className="modal-overlay fixed top-0 left-0 w-[100vw]  h-[100vh] bg-[#777777b3] z-[9999999] flex justify-center items-center  transition-all duration-300 ease-in-out">
                        <div className="relative transition-all duration-300 ease-in-out xs:border-[1px] xs:border-solid xs:border-white">
                            {/* Responsive container for video */}
                            <div className='relative pt-[56.25%] w-[60vw] h-[100%] xs:w-[80vw] xs:h-[58vh] overflow-hidden m-0 '>
                                <iframe
                                    src={`https://www.youtube.com/embed/4Qnb-H1r0Ls?rel=0`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                    className='absolute top-[50%] left-[50%] h-[100%] w-[100%] '
                                    style={{
                                        transform: "translate(-50%, -50%)"
                                    }}
                                />
                            </div>
                            <button
                                onClick={closeModal}
                                className="absolute top-[-3%] right-[-3%] xs:top-[-3%] xs:right-[-7%] px-[10px] py-[5px] cursor-pointer">
                                <img
                                    className="w-[30px] h-[30px]"
                                    src="/images/close.png"
                                    alt="cross"
                                />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
