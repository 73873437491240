import React, { useState } from "react";
import useBreakPoints from "../Hooks/useBreakPoints";

export const FloorPlans = () => {
    const { viewportWidth } = useBreakPoints();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            closeModal();
        }
    };

    return (
        <>
            <div
                id="Features"
                className={`about-details-page w-[100%] h-[100%] pb-[5vh] xs:pb-[8vh]
         ${viewportWidth > 1850 ? 'pl-[15vw] pr-[15vw]' :
                        viewportWidth > 1750 ? 'pl-[14vw] pr-[17vw]' :
                            viewportWidth > 1650 ? 'pl-[12vw] pr-[14vw]' :
                                viewportWidth > 1599 ? 'pl-[12vw] pr-[14vw]' :
                                    'px-[4vw] xs:pt-[2vh] xs:px-[12vw]'}
       `}
                style={{
                    backgroundColor: `${isModalOpen ? '#777777b3' : '#f5f5f5'}`,
                }}>

                <div>
                    <div className="text-[36px] font-[300] xs:text-[25px] font-[Lato] mt-[25px] text-center text-[#0F0F0F] uppercase">
                        FLOOR PLANS - <span><strong>FINTECH SQUARE, MOHALI</strong></span>
                    </div>

                    <div className="flex justify-center items-center mt-[3vh] mb-[8vh] gap-0 w-[100%]">
                        <div className="bg-[#f2195d] h-[3px] w-[50px] z-[100] flex items-center justify-center" />
                        <div className="bg-[#dad5d5] h-[3px] w-[50px] z-[100] flex items-center justify-center" />
                    </div>

                    <div className="w-[100%] h-[100%] flex flex-col justify-center items-center ">
                        <div className="w-[350px] h-[200px]">
                            <img
                            alt="dummy"
                                className="w-[100%] h-[100%] object-cover bg-no-repeat"
                                src="/images/ground_floor.jpg"
                            />
                        </div>
                        <div className="w-[350px] h-max bg-white text-center flex gap-[10px] py-[10px] flex-col justify-center items-center" >
                            <div className="text-[#0F0F0F]  text-[18px]">
                                GROUND FLOOR PLAN
                            </div>
                            <div
                                onClick={openModal}
                                className="font-[400] uppercase text-[16px] px-[12px] flex justify-center items-center border-[1px] border-solid border-[#23344d] hover:bg-[#1b2c49] bg-[#23344d] text-white rounded-[5px] py-[4px] tracking-[1px] cursor-pointer transition-all ease-linear w-max"
                            >
                                view floor plan
                            </div>
                        </div>
                    </div>
                </div>

                {isModalOpen && (
                    <div
                        onClick={handleOutsideClick}
                        className="modal-overlay fixed top-0 left-0 w-[100vw]  h-[100vh] bg-[#777777b3] z-[9999999] flex justify-center items-center  transition-all duration-300 ease-in-out">
                        <div className="relative transition-all duration-300 ease-in-out xs:border-[1px] xs:border-solid xs:border-white">
                            <img
                                className="transition-all duration-300 ease-in-out max-w-[90vw] xs:max-w-[90vw] max-h-[90vh] object-contain"
                                src="/images/ground_floor.jpg"
                                alt="Selected" />
                            <button
                                onClick={closeModal}
                                className="absolute top-[-3%] right-[-3%] xs:top-[-7%] xs:right-[-7%] px-[10px] py-[5px] cursor-pointer">
                                <img
                                    className="w-[30px] h-[30px]"
                                    src="/images/close.png"
                                    alt="cross"
                                />

                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
