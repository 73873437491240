import React from 'react'
import useBreakPoints from "../Hooks/useBreakPoints";


const Footer = () => {
    const { viewportWidth } = useBreakPoints();

    return (
        <>
            <div className='w-[100%] h-[4px] bg-[#1f9b9d] '></div>
            <div id="Contact" className={` bg-[#f7f7f7] w-[100%]  mb-0 py-[3vh] xs:pt-[30px]
         ${viewportWidth > 1850 ? 'pl-[18vw] pr-[19vw]' :
                    viewportWidth > 1750 ? 'pl-[14vw] pr-[17vw]' :
                        viewportWidth > 1650 ? 'pl-[12vw] pr-[14vw]' :
                            viewportWidth > 1599 ? 'pl-[12vw] pr-[14vw]' :
                                'xs:px-[12vw] px-[4vw]'}
        `}>
                <div className='w-[100%] flex  xs:gap-y-[4vh] xs:gap-x-[10px] xs:justify-start xs:items-start justify-between text-black xs:flex-wrap'>

                    <div className='w-[315px] h-[90px]  xs:w-[100%]'>
                        <img
                            className="w-[100%] h-[100%] bg-cover bg-center bg-no-repeat"
                            src="/images/Fintech_Square.png" alt="dummy" />
                    </div>

                    <div className='flex flex-col xs:w-[45%]  h-max gap-y-[10px] items-start'>
                        <div>
                            <div className='uppercase font-[500] text-[#433f3d] text-[20px] whitespace-nowrap xs:text-[18px]'>
                                SITE OFFICE
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-[-5px] '>
                            <div className='text-[14px] xs:text-[12px] font-[Lato] capitalize font-[600] text-[#433f3d]'>Fintech Square,</div>
                            <div className='text-[14px] xs:text-[12px] font-[Lato] capitalize font-[400] text-[#433f3d]'>CP-2,Industrial Focal Point,</div>
                            <div className='text-[14px] xs:text-[12px] font-[Lato] capitalize font-[400] text-[#433f3d] '> Phase: 8A, Sector - 75, Mohali</div>
                        </div>
                    </div>

                    <div className='flex flex-col xs:w-[45%]  h-max gap-y-[10px] items-start'>
                        <div>
                            <div className='uppercase font-[500] text-[#433f3d] text-[20px] whitespace-nowrap  xs:text-[18px]'>
                                CORPORATE OFFICE
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-[-5px]'>
                            <div className='text-[14px] xs:text-[12px] font-[Lato] capitalize font-[600] text-[#433f3d]'>VRS Ventures,</div>
                            <div className='text-[14px] xs:text-[12px] font-[Lato] capitalize font-[400] text-[#433f3d] '>2nd Floor, Vista tower,</div>
                            <div className='text-[14px] xs:text-[12px] font-[Lato] capitalize font-[400] text-[#433f3d] '>Phase 8A, Sector - 75, Mohali</div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Footer